<template>
  <div class="profile-form">
    <div class="form-wrapper form-style-1" :class="formState">
      <div class="form">
        <form action="" @focusout="change" @input="change" @submit="submit" novalidate>
          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.firstname.errorMessage }">
              <label for="firstname">First name</label>
              <div class="input-field">
                <input type="text" name="firstname" id="firstname" data-rules='["required"]' v-model="firstname" />
              </div>
            </div>
            <p class="error-message" v-if="validation.firstname.errorMessage">
              {{ validation.firstname.errorMessage }}
            </p>
          </div>

          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.lastname.errorMessage }">
              <label for="lastname">Last name</label>
              <div class="input-field">
                <input type="text" name="lastname" id="lastname" data-rules='["required"]' v-model="lastname" />
              </div>
            </div>
            <p class="error-message" v-if="validation.lastname.errorMessage">
              {{ validation.lastname.errorMessage }}
            </p>
          </div>

          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.email.errorMessage }">
              <label for="email">Email</label>
              <div class="input-field">
                <input type="email" name="email" id="email" data-rules='["required"]' v-model="email" readonly />
              </div>
            </div>
            <p class="error-message" v-if="validation.email.errorMessage">
              {{ validation.email.errorMessage }}
            </p>
          </div>

          <!-- TODO: Temporary commented to make phone field single box, finally clean up or uncomment -->
          <!-- <div class="form-group">
            <div
              class="input-field-wrapper phone-input-field-wrapper"
              :class="{
                invalid: validation.countryCode.errorMessage || validation.phone.errorMessage,
              }"
            >
              <label for="phone">Phone</label>
              <div class="input-field-inner-wrapper">
                <div class="country-code-dropdown">
                  <select
                    name="countryCode"
                    placeholder="Search"
                    ref="countryCodeDropdownEl"
                    data-rules='["required"]'
                    v-model="countryCode"
                  ></select>
                </div>
                <div class="input-field">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    data-rules='["required"]'
                    v-model="phone"
                  />
                </div>
              </div>
            </div>

            <p
              class="error-message"
              v-if="validation.countryCode.errorMessage || validation.phone.errorMessage"
            >
              {{ validation.phone.errorMessage }}
              <span v-if="!validation.phone.errorMessage">{{
                validation.countryCode.errorMessage
              }}</span>
            </p>
          </div> -->

          <div class="form-group">
            <div class="input-field-wrapper" :class="{ invalid: validation.phone.errorMessage }">
              <label for="phone">Mobile</label>
              <div class="input-field">
                <input type="tel" name="phone" id="phone" data-rules='["required"]' v-model="phone" />
              </div>
            </div>
            <p class="error-message" v-if="validation.phone.errorMessage">
              {{ validation.phone.errorMessage }}
            </p>
          </div>

          <div class="form-error" v-if="error">
            <div class="error">{{ error }}</div>
          </div>

          <!-- formChanged -->
          <div class="form-action-group">
            <button type="submit" class="">Save</button>
          </div>
        </form>
      </div>
      <div class="loading-overlay">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as TomSelect from 'tom-select';
import formValidation from '@/mixins/formValidation';

export default {
  name: 'ProfileForm',
  mixins: [formValidation],
  data() {
    return {
      validation: {
        inputElements: [],
        firstname: {},
        lastname: {},
        email: {},
        countryCode: {},
        phone: {},
      },
      firstname: '',
      lastname: '',
      email: '',
      countryCode: '',
      phone: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.initValidation();

    this.firstname = this.user.firstName;
    this.lastname = this.user.lastName;
    this.email = this.user.email;
    this.phone = this.user.mobileNumber;

    /* this.$store.dispatch('fetchCountries').then(() => {
      this.initCountryCodeDropdown();
    }); */
  },
  methods: {
    initCountryCodeDropdown() {
      const countries = JSON.parse(JSON.stringify(this.$store.state.countries));
      const flagsBaseUrl = this.$store.state.flagsBaseUrl;

      new TomSelect(this.$refs.countryCodeDropdownEl, {
        valueField: 'dialCode',
        searchField: 'dialCode',
        options: countries,
        maxOptions: 300,
        render: {
          option: function(data, escape) {
            return (
              '<div class="country-code">' +
              '<div class="flag-wrapper">' +
              '<div class="flag" style="background-image: url(\'' +
              flagsBaseUrl +
              data.flag +
              '\')"></div>' +
              '</div>' +
              '<div class="name">+' +
              escape(data.dialCode) +
              '</div>' +
              '</div>'
            );
          },
          item: function(data, escape) {
            return (
              '<div class="country-code">' +
              '<div class="flag-wrapper">' +
              '<div class="flag" style="background-image: url(\'' +
              flagsBaseUrl +
              data.flag +
              '\')"></div>' +
              '</div>' +
              '<div class="name">+' +
              escape(data.dialCode) +
              '</div>' +
              '</div>'
            );
          },
        },
        items: '971',
      });
    },
    send() {
      this.error = null;

      this.$store
        .dispatch('updateProfile', {
          firstName: this.firstname,
          lastName: this.lastname,
          mobile: this.phone,
        })
        .then(() => {
          this.formState = null;
          this.formChanged = false;

          window.dispatchEvent(new CustomEvent('addSuccessMessage', {detail: {message: 'Profile Saved'}}));
        })
        .catch((error) => {
          this.formState = null;
          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/partials/variables.scss';
@import '../../../node_modules/tom-select/dist/css/tom-select.css';
@import '../../scss/partials/tom-select-overrides.scss';

.profile-form {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #c1c1c1;

  .form {
    padding: 20px 30px 5px;
  }

  @media (max-width: 640px) {
    margin-bottom: 50px;

    .form {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>

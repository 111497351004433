<template>
  <div class="change-password-form">
    <div class="head">
      <h5>Change your password</h5>
      <div class="form-wrapper form-style-2" :class="formState">
        <div class="form">
          <form @focusout="change" @input="change" @submit="submit" novalidate>
            <div class="row">
              <div class="form-group">
                <div class="input-field-wrapper" :class="{ invalid: validation.oldPassword.errorMessage }">
                  <div class="input-field">
                    <input type="password" placeholder="Old Password" name="oldPassword" id="oldPassword" data-rules='["required"]' v-model="oldPassword" />
                  </div>
                </div>
                <p class="error-message" v-if="validation.oldPassword.errorMessage">
                  {{ validation.oldPassword.errorMessage }}
                </p>
              </div>

              <div class="form-group new-password-form-group">
                <div class="input-field-wrapper" :class="{ invalid: validation.newPassword.errorMessage }">
                  <div class="input-field">
                    <input type="password" placeholder="New Password" name="newPassword" id="newPassword" data-rules='["required", "password"]' v-model="newPassword" />
                  </div>
                </div>
                <p class="error-message" v-if="validation.newPassword.errorMessage">
                  {{ validation.newPassword.errorMessage }}
                </p>
              </div>

              <button type="submit" class="">Change</button>
            </div>
          </form>
          <div class="form-error" v-if="error">
            <div class="error">{{ error }}</div>
          </div>
        </div>
        <div class="loading-overlay">
          <div class="loader"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formValidation from '@/mixins/formValidation';
//import axios from 'axios';
/***global handleErrors*/

export default {
  name: 'ChangePasswordForm',
  mixins: [formValidation],
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      validation: {
        inputElements: [],
        oldPassword: {},
        newPassword: {},
      },
    };
  },
  mounted() {
    this.initValidation();
  },
  methods: {
    send() {
      this.success = null;
      this.error = null;

      this.$store
        .dispatch('changePassword', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        .then(() => {
          this.formState = null;
            this.oldPassword = '';
            this.newPassword = '';
            window.dispatchEvent(new CustomEvent('addSuccessMessage', {detail: {message: 'Password Changed'}}));
        })
        .catch((error) => {
          this.formState = null;
          this.error = error;
        });

      /* handleErrors(async () => {
        const response = await axios.post('/user/changePassword', {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        });

        console.log(response);
        //TODO: Show Error/Success form here.
      })(); */
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/partials/variables.scss';

.change-password-form {
  border-radius: 5px;
  background-color: rgba(188, 184, 254, 0.14);
  padding: 25px 30px 20px;
  margin-bottom: 100px;

  h5 {
    font-size: 20px;
    font-weight: 600;
    color: #252423;
    margin-bottom: 15px;
  }

  .row {
    display: flex;
    .form-group {
      padding-right: 10px;
      flex: 1;
    }
  }

  .form {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .row {
      display: block;

      .form-group {
        padding-right: 0px;
      }
    }
  }

  @media (max-width: 640px) {
    padding-left: 20px;
    padding-right: 20px;

    .row {
      .form-group {
        padding-right: 0px;
      }
    }
  }
}

.form-style-2 {
  position: relative;

  .input-field-wrapper {
    &.invalid {
      input[type='password'] {
        border-color: #f64b3d;
      }
    }
  }

  input[type='password'] {
    border-radius: 27px;
    border: solid 2px rgba(145, 143, 168, 0.32);
    background-color: #ffffff;
    height: 54px;
    color: #252423;
    padding: 0px 20px;
    font-size: 16px;
    font-weight: 300;
    outline: 0;
    line-height: 54px;
    width: 100%;

    &:focus {
      border-color: #005fcc;
    }

    &::placeholder {
      color: rgba(37, 36, 35, 0.5);
    }
  }

  button {
    height: 54px;
    border-radius: 50px;
    box-shadow: 0 2px 20px 0 rgba(148, 102, 0, 0.47);
    background-color: #e79f00;
    font-size: 22px;
    line-height: 54px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    border: 0px none;
    padding: 0px 30px;
    outline: none;
  }

  .error-message {
    margin-bottom: 0px;
    margin-top: 2px;
    color: #dd0000;
    margin-left: 15px;
  }

  .form-error {
    color: #dd0000;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;

    .error {
      background-image: url($srcPath+'assets/red-error-icon.svg');
      background-repeat: no-repeat;
      background-position: left top 4px;
      padding: 2px 0px 2px 20px;
      line-height: 1.4;
    }
  }

  .loading-overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 1;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  .loader {
    border-top-color: rgba(37, 36, 35, 0.2);
    border-right-color: rgba(37, 36, 35, 0.2);
    border-bottom-color: rgba(37, 36, 35, 0.2);
    border-left-color: #252423;
    border-width: 3px;
    width: 30px;
    height: 30px;
  }

  &.loading {
    .form,
    .form-error {
      opacity: 0.1;
    }

    .loading-overlay {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    .form-group {
      margin-bottom: 20px;
    }

    button {
      min-width: 200px;
    }
  }
}
</style>

<template>
  <nav class="left-nav" @click=toggle :class="{expand: expand}">
    <router-link to="/orders" class="orders">Orders</router-link>
    <router-link to="/transactions" class="transactions">Transactions</router-link>
    <router-link to="/addresses" class="addresses">Addresses</router-link>
    <router-link to="/settings" class="addresses">Settings</router-link>
    <span class="separator-line"></span>
    <router-link to="/logout" class="logout">Logout</router-link>
  </nav>
</template>


<script>
export default {
  name: "LeftMenu",
  data() {  
    return {
      expand: false
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    }
  }
}
</script>
